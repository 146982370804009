.category__BG{
    background: #ededed;
}
.disabled{
    pointer-events: none;
}

.wordWrap{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
}